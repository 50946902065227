import { SearchParams } from '@/global-types'
import { CheckboxStage, FacetGroup, SplitFacets, FacetInfo, PublicationFacets, FacetsFields, FacetsValue } from './types'
import parser, { Data } from '@/query-parser/parser'

export const defaultEmptyPublicationFacets = (): PublicationFacets => ({
  authors: [],
  journals: [],
  publicationYears: [],
  primaryClassifications: [],
  entryTypes: [],
  itemTypes: [],
  reviewStatuses: [],
  publicationTypes: [],
  institutions: [],
  statuses: [],
})

export function queryIncludedAndExcludedFacets(groups: FacetGroup[]): FacetGroup[] {
  return groups
    .map(group => {
      return {
        ...group,
        items: group.items.filter(item =>
          item.stage === CheckboxStage.Include ||
          item.stage === CheckboxStage.Exclude
        ),
      }
    })
}

export function convertSectionsToFacetsString(groups: FacetGroup[]): string {
  return queryIncludedAndExcludedFacets(groups)
    .map(group => {
      const mustBeIncluded = group.items
        .filter(item => item.stage === CheckboxStage.Include)
        .map(item => item.value)

      const mustBeExcluded = group.items
        .filter(item => item.stage === CheckboxStage.Exclude)
        .map(item => item.value)

      const processValue = (value: string, group: FacetGroup): string => {
        if (group.allowPunctuation) {
          return `v'${value}'`
        }

        return group.parensForSingleValue
          ? `(${value})`
          : `"${value}"`
      }

      let query = ''

      /* ----------------------------- INCLUDED VALUES ---------------------------- */
      if (mustBeIncluded.length > 1 || hasInnerOR(mustBeIncluded)) {
        if (group.linkedANDSearchSelected) {
          mustBeIncluded.forEach(value => {
            query += `${group.id}:${processValue(value, group)} `
          })
        } else {
          query += `${group.id}:(${joinValuesWithOR(mustBeIncluded, group.allowPunctuation)})`
        }
      } else if (mustBeIncluded.length === 1) {
        query += `${group.id}:${processValue(mustBeIncluded[0], group)}`
      }

      /* ----------------------------- EXCLUDED VALUES ---------------------------- */
      if (mustBeExcluded.length > 1 || hasInnerOR(mustBeExcluded)) {
        if (group.linkedANDSearchSelected) {
          mustBeExcluded.forEach(value => {
            query += ` NOT ${group.id}:${processValue(value, group)}`
          })
        } else {
          query += ` NOT ${group.id}:(${joinValuesWithOR(mustBeExcluded, group.allowPunctuation)})`
        }
      } else if (mustBeExcluded.length === 1) {
        query += ` NOT ${group.id}:${processValue(mustBeExcluded[0], group)}`
      }

      return query.trim()
    })
    .filter(query => query.trim() !== '')
    .join(' ')
}

function joinValuesWithOR(values: string[], allowPunctuation = false): string {
  return values
    .map(value => value
      .split(/\s\|\s/g)
      .map(v => {
        if (allowPunctuation) {
          return `v'${v}'`
        }

        return `"${v}"`
      })
      .join(' OR ')
    )
    .join(' OR ')
}

function hasInnerOR(values: string[]): boolean {
  return values.find(value => value.includes('|')) !== undefined
}

export function splitFacetsStringIntoIncludedAndExcluded(text: string): SplitFacets {
  const result: SplitFacets = { included: [], excluded: [] }

  if (text.trim().length === 0) {
    return result
  }

  // remove double quotes
  text = text.replace(/"/g, '')

  const parserResult = parser.parse(text, {})

  if (parserResult !== null) {
    parserResult.fields.forEach(field => {
      if (field.value) {
        switch (field.value.type) {
          case 'DATA':
            if (field.operator && field.operator.value === 'NOT') {
              result.excluded.push({ field: field.name.value, values: [field.value.value as string] })
            } else {
              result.included.push({ field: field.name.value, values: [field.value.value as string] })
            }
            break
          case 'GROUP': {
            const values = (field.value.value as Data[]).map(item => item.value)

            if (field.operator && field.operator.value === 'NOT') {
              result.excluded.push({ field: field.name.value, values })
            } else {
              result.included.push({ field: field.name.value, values })
            }
            break
          }
        }
      }
    })
  }

  return result
}

export function convertToFacetsStringAndJoinByOR(fields: FacetInfo[]): string {
  return fields
    .map(item => `${item.field}:(${item.values.join(' OR ')})`)
    .join(' OR ').trim()
}

export function convertToFacetsString(fields: FacetInfo[]): string {
  return fields
    .map(item => `${item.field}:(${item.values.join(' OR ')})`)
    .join(' ').trim()
}

export function changeCheckboxStage(groups: FacetGroup[], fields: FacetInfo[], stage: CheckboxStage): FacetGroup[] {
  return groups.map(group => {
    fields = fields.map(v => {
      return {
        ...v,
        values: v.values
          .map(value => value.replace(/"|^v'|'/g, '')),
      }
    })

    fields
      .filter(field => field.field === group.id)
      .map(field => {
        group.items = group.items.map(item => {
          const values = item.value.split('|')
          const shouldChangeState = values.reduce((acc, cur) => {
            return acc || field.values.map(value => value.replace(/"/, '')).includes(cur.trim())
          }, false)

          if (shouldChangeState) {
            item.stage = stage
          }

          return item
        })
      })

    return { ...group }
  })
}

export function sortIncludedAndExcluded(groups: FacetGroup[]): FacetGroup[] {
  return groups.map(group => {
    const sort = group.operations?.sort
    if (sort) {
      const unselected = group.items.filter(item => item.stage === CheckboxStage.Unselected)
      const marked = group.items.filter(item => item.stage !== CheckboxStage.Unselected)

      group.items = [...sort(marked), ...sort(unselected)]
    }

    return group
  })
}

export function addMissingFacets<Type extends FacetsFields>(unfilteredFacets: Type, filteredFacets: Type): Type {
  const _unfilteredFacets = JSON.parse(JSON.stringify(unfilteredFacets))
  const _filteredFacets = JSON.parse(JSON.stringify(filteredFacets))

  for (const key in _unfilteredFacets) {
    const unfilteredFacetItems: FacetsValue[] = _unfilteredFacets[key]
    const filteredFacetItems: FacetsValue[] = _filteredFacets[key]

    if (!filteredFacetItems) {
      _filteredFacets[key] = unfilteredFacetItems.map(item => ({ ...item, total: 0 }))
      continue
    }

    const missingFacetItems = unfilteredFacetItems.filter(unfilteredFacetItem => {
      return !filteredFacetItems.some(filteredFacetItem => {
        return filteredFacetItem.value === unfilteredFacetItem.value
      })
    })

    if (missingFacetItems.length > 0) {
      filteredFacetItems.push(...missingFacetItems.map(item => ({ ...item, total: 0 })))
    }

    _filteredFacets[key] = filteredFacetItems
  }

  return _filteredFacets
}

// export function processUnappliedChanges(
//   unappliedGroups: FacetGroup[],
//   groups: FacetGroup[]
// ): FacetGroup[] {
//   if (unappliedGroups.length === 0) {
//     return groups
//   }

//   return groups.map(group => {
//     const unappliedGroup = unappliedGroups.find(g => g.id === group.id)

//     if (!unappliedGroup) return group

//     if (unappliedGroup.unappliedChanges) {
//       // make a copy of the array
//       group.unappliedChanges = [...unappliedGroup.unappliedChanges]
//     }

//     group.items = group.items.map(item => {
//       const unappliedChange = unappliedGroup.items.find(i => i.value === item.value)

//       if (unappliedChange) {
//         return {
//           ...item,
//           stage: unappliedChange.stage,
//         }
//       }

//       return item
//     })

//     return group
//   })
// }

function checkForSelectedLinkedANDSearchSwitch(linkedANDSearchFieldsActive: string[], facetGroups: FacetGroup[]): FacetGroup[] {
  return facetGroups.map(group => {
    if (linkedANDSearchFieldsActive.includes(group.id)) {
      group.linkedANDSearchSelected = true
    }

    return group
  })
}

export async function fetchFacets(
  searchParams: SearchParams,
  fetcher: (query: string, abortPrevious?: boolean) => Promise<FacetsFields>,
  converterToFacetsGroups: <Type extends FacetsFields>(facets: Type) => FacetGroup[],
  nestedComponentFormat = false

): Promise<FacetGroup[]> {
  const abortPreviousSearch = !nestedComponentFormat
  const query = searchParams.query.trim()
  const facetsFromURL = searchParams.facets ? searchParams.facets : ''
  const unfilteredFacets = await fetcher(query, abortPreviousSearch)
  const splitFacets = splitFacetsStringIntoIncludedAndExcluded(facetsFromURL)

  if (unfilteredFacets === null) return []

  let facetGroups

  if (facetsFromURL.trim() !== '') {
    let filteredFacets = await fetcher(`${query} ${facetsFromURL}`, abortPreviousSearch)
    filteredFacets = addMissingFacets(unfilteredFacets, filteredFacets)

    facetGroups = converterToFacetsGroups(filteredFacets)
  } else {
    facetGroups = converterToFacetsGroups(unfilteredFacets)
  }

  if (searchParams.linkedANDSearchFieldsActive) {
    facetGroups = checkForSelectedLinkedANDSearchSwitch(searchParams.linkedANDSearchFieldsActive, facetGroups)
  }
  facetGroups = changeCheckboxStage(facetGroups, splitFacets.included, CheckboxStage.Include)
  facetGroups = changeCheckboxStage(facetGroups, splitFacets.excluded, CheckboxStage.Exclude)
  facetGroups = sortIncludedAndExcluded(facetGroups)

  return facetGroups
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function sortKeysAndConvertToJSON(obj: Record<string, unknown>): string {
  if (obj === null || typeof obj !== 'object') return JSON.stringify(obj)

  // Step 1: Retrieve the object's keys
  const keys = Object.keys(obj)

  // Step 2: Sort the keys
  keys.sort()

  // Step 3: Create a new object with sorted keys
  const sortedObj = {}
  for (const key of keys) {
    sortedObj[key] = obj[key]
  }

  // Step 4: Convert the sorted object to JSON string
  return JSON.stringify(sortedObj)
}
