
export enum CheckboxStage {
  Include = 'Include',
  Exclude = 'Exclude',
  Unselected = 'Unselected',
}

export type FacetsValue = {
  id: string
  value: string
  total: number
}

export type PublicationFacets = {
  authors: FacetsValue[]
  journals: FacetsValue[]
  publicationYears: FacetsValue[]
  primaryClassifications: FacetsValue[]
  entryTypes: FacetsValue[]
  itemTypes: FacetsValue[]
  reviewStatuses: FacetsValue[]
  publicationTypes: FacetsValue[]
  institutions: FacetsValue[]
  statuses: FacetsValue[]
}

export type AuthorFacets = {
  primaryClassifications: FacetsValue[],
}

export type FacetsFields = PublicationFacets | AuthorFacets

export type FacetItem = {
  value: string
  label: string
  count: number
  stage: CheckboxStage
}

export type UnappliedChange = {
  original: FacetItem
  modified: FacetItem
}

export type FacetGroupOperations = {
  filter?: (value: string) => Promise<FacetItem[] | null>
  sort?: (items: FacetItem[]) => FacetItem[]
}

export type LinkedANDSearchLabels = {
  terms: string,
  document: string,
}

export type FacetGroup = {
  id: string
  title: string
  items: FacetItem[]
  searchable?: boolean
  loading?: boolean
  parensForSingleValue?: boolean // Use parentheses for single value queries instead of quotes
  allowPunctuation?: boolean // Allow punctuation in queries
  linkedANDSearchSelected?: boolean // instead of field:(value1 OR value2) use field:value1 field:value2
  showLinkedANDSearchSwitch?: boolean
  linkedANDSearchLabels?: LinkedANDSearchLabels // Data for linked and search
  operations?: FacetGroupOperations
  unappliedChangesExist?: boolean
}

export type FacetInfo = {
  field: string,
  values: string[]
}

export type SplitFacets = {
  included: FacetInfo[],
  excluded: FacetInfo[],
}
