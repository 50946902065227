
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import ThreeStageCheckbox from './ThreeStageCheckbox.vue'
import { CheckboxStage, FacetGroup } from './types'

@Component({
  components: {
    ThreeStageCheckbox,
  },
})
export default class FacetsSelector extends Vue {
  @Prop({ required: true }) group!: FacetGroup
  @Prop({ default: false }) unsubbed!: boolean

  switchValue = false
  searchText = ''
  loading = false

  //
  // WATCHER
  //
  @Watch('group', { deep: true, immediate: true })
  onGroupChange() {
    this.loading = this.group.loading || false
    this.switchValue = this.group.linkedANDSearchSelected || false
  }

  @Watch('isSwitchDisabled')
  onSwitchDisabledChange() {
    if (this.isSwitchDisabled) {
      this.switchValue = false
    }
  }

  @Watch('switchValue')
  onSwitchValueChange() {
    this.onChangeLinkedANDSearchSwitch(this.switchValue)
  }

  //
  // HOOKS
  //

  mounted() {
    this.initEscapeListener()
  }

  beforeDestroy() {
    this.delEscapeListener()
  }

  //
  // COMPUTED PROPERTIES
  //

  get opacityWhenLoading() {
    return `opacity:${this.loading ? '0' : '1'};`
  }

  get containerClass() {
    const minHeight = this.loading ? 'content-min-height' : ''
    const overflow = !this.unsubbed ? 'overflow-auto' : ''

    return `${overflow} ${minHeight}`
  }

  get isSwitchDisabled() {
    return this.group.items.map(i => i.stage === CheckboxStage.Unselected).reduce((a, b) => a && b, true)
  }

  //
  // METHODS
  //

  onChangeCheckbox(itemIndex: number, newStage: CheckboxStage) {
    const updatedGroup = JSON.parse(JSON.stringify(this.group))
    updatedGroup.items[itemIndex].stage = newStage
    this.$emit('change', updatedGroup)
  }

  onChangeLinkedANDSearchSwitch(value: boolean) {
    const updatedGroup = JSON.parse(JSON.stringify(this.group))
    updatedGroup.linkedANDSearchSelected = value ? true : undefined
    this.$emit('change', updatedGroup)
  }

  onClickApplyHandler() {
    this.$emit('apply', this.group.id)
  }

  onClickClearHandler() {
    const updatedGroup = JSON.parse(JSON.stringify(this.group))
    updatedGroup.items.forEach(item => (item.stage = CheckboxStage.Unselected))
    updatedGroup.linkedANDSearchSelected = undefined
    updatedGroup.unappliedChangesExist = undefined
    this.switchValue = false

    this.$emit('clear', updatedGroup)
  }

  async onSearchHandler(searchText: string) {
    this.loading = true
    this.$emit('search', searchText, this.group.id)
  }

  initEscapeListener() {
    this.$el.addEventListener('keydown', this.checkForEsc)
  }

  delEscapeListener() {
    this.$el.removeEventListener('keydown', this.checkForEsc)
  }

  // TODO: Check that the two following methods are working propertly with the changes I made
  checkForEsc(event) {
    if (event.key === 'Escape') {
      (this.$refs.applyAndFocusSearchBtn as HTMLLinkElement).focus()
    }
  }

  applyAndFocusSearch() {
    this.onClickApplyHandler()
    const rawresults = [...document.getElementsByClassName('search-results')]
    if (rawresults.length === 1) {
      // With only one search-result on the page, it can just be focused
      (rawresults[0] as HTMLElement).focus()
    } else {
      // Focus the one that's visible (the Author profile has 4)
      const results = rawresults.filter(x => {
        const closest = x.closest('.tab-pane')
        return closest && (closest as HTMLElement).style.display === ''
      })
      if (results.length === 1) {
        (results[0] as HTMLElement).focus()
      }
    }
  }

  showUnsubbedModal() {
    this.$emit('showUnsubbedModal')
  }
}
