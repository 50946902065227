
import { Card } from '@/components/card'
import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator'
import FacetsBody from './FacetsBody.vue'
import { CheckboxStage, FacetGroup } from './types'

@Component({
  components: {
    Card,
    FacetsBody,
  },
})
export default class Facets extends Vue {
  @PropSync('showModal', { type: Boolean, default: false }) syncedShowModal!: boolean

  @Prop({ required: true }) groups!: FacetGroup[]
  @Prop({ default: false, type: Boolean }) nestedComponentFormat!: boolean
  @Prop({ default: false, type: Boolean }) loading!: boolean
  @Prop({ default: false, type: Boolean }) subqueryInProgress!: boolean
  @Prop({ default: false }) unsubbed!: boolean

  subscriptionModalVisible = false
  localCopyOfGroups: FacetGroup[] = []
  originalGroups: FacetGroup[] = []

  @Watch('groups', { deep: true, immediate: true })
  onGroupsChanged() {
    if (this.groups.length === 0) {
      this.localCopyOfGroups = []
      this.originalGroups = []
    } else {
      this.originalGroups = JSON.parse(JSON.stringify(this.groups))

      if (this.localCopyOfGroups.length === 0) {
        this.localCopyOfGroups = JSON.parse(JSON.stringify(this.groups))
      }

      this.localCopyOfGroups = this.localCopyOfGroups.map(g => {
        if (this.hasGroupChanged(g)) {
          return g
        } else {
          return this.originalGroups.find(og => og.id === g.id) || g
        }
      })
    }
  }

  //
  // COMPUTER PROPERTIES
  //
  get clearBtnVariant() {
    return this.nestedComponentFormat ? 'outline-primary' : ''
  }

  get enableApplyButton() {
    return this.localCopyOfGroups.map(g => this.hasGroupChanged(g)).reduce((a, b) => a || b, false)
  }

  get enableClearButton() {
    return this.localCopyOfGroups.map(g => {
      return g.items.filter(i => i.stage !== CheckboxStage.Unselected).length > 0 ||
        g.linkedANDSearchSelected
    }).reduce((a, b) => a || b, false)
  }

  //
  // METHODS
  //

  hasGroupChanged(updatedGroup: FacetGroup) {
    const originalGroup = this.originalGroups.find(g => g.id === updatedGroup.id)

    if (!updatedGroup || !originalGroup) {
      return false
    }

    const updatedGroupItems = updatedGroup.items.map(i => ({ ...i, count: 0 }))// .sort((a, b) => a.value > b.value ? 1 : -1)
    const originalGroupItems = originalGroup.items.map(i => ({ ...i, count: 0 }))// .sort((a, b) => a.value > b.value ? 1 : -1)

    const updatedGroupProperties = {
      linkedANDSearchSelected: updatedGroup.linkedANDSearchSelected,
    }
    const originalGroupProperties = {
      linkedANDSearchSelected: originalGroup.linkedANDSearchSelected,
    }

    return JSON.stringify(updatedGroupItems) !== JSON.stringify(originalGroupItems) ||
      JSON.stringify(updatedGroupProperties) !== JSON.stringify(originalGroupProperties)
  }

  clearAllGroups() {
    const groups = this.groups.map(g => {
      return {
        ...g,
        items: g.items.map(i => ({ ...i, stage: CheckboxStage.Unselected })),
        linkedANDSearchSelected: undefined,
      }
    })
    this.localCopyOfGroups = JSON.parse(JSON.stringify(groups))
    this.originalGroups = []

    this.$emit('clear', groups)
  }

  onClearGroupHandler(updatedGroup: FacetGroup) {
    const index = this.localCopyOfGroups.findIndex(g => g.id === updatedGroup.id)

    if (index !== -1) {
      this.localCopyOfGroups.splice(index, 1, updatedGroup)
    }

    this.$emit('clearGroup', updatedGroup)
  }

  applyAllGroups() {
    const updatedGroups = JSON.parse(JSON.stringify(this.localCopyOfGroups))
    this.localCopyOfGroups = []
    this.originalGroups = []

    this.$emit('apply', updatedGroups.sort((a, b) => a.id > b.id ? 1 : -1))
  }

  onApplyGroupHandler(groupId: string) {
    const index = this.localCopyOfGroups.findIndex(g => g.id === groupId)

    if (index !== -1) {
      this.localCopyOfGroups[index].unappliedChangesExist = undefined
      this.$emit('applyGroup', this.localCopyOfGroups[index])
    }
  }

  onChangeGroupHandler(updatedGroup: FacetGroup) {
    const index = this.localCopyOfGroups.findIndex(g => g.id === updatedGroup.id)

    if (index !== -1) {
      updatedGroup.unappliedChangesExist = this.hasGroupChanged(updatedGroup) ? true : undefined
      this.localCopyOfGroups.splice(index, 1, updatedGroup)
    }
  }

  closeFilters() {
    this.$emit('close')
  }

  onInputHandler(groups: FacetGroup[]) {
    this.groups = groups
  }

  async onSearchHandler(text: string, groupId: string) {
    const group = this.groups.find(g => g.id === groupId)
    const index = this.localCopyOfGroups.findIndex(g => g.id === groupId)

    if (index === -1) {
      return
    }

    const localCopyGroup = this.localCopyOfGroups[index]

    if (localCopyGroup && group && group.operations && group.operations.filter) {
      const newItems = await group.operations.filter(text)
      const selectedItems = localCopyGroup.items.filter(i => {
        return i.stage === CheckboxStage.Include || i.stage === CheckboxStage.Exclude
      })

      if (newItems === null) {
        localCopyGroup.loading = true
      } else if (newItems !== null) {
        localCopyGroup.items = [...selectedItems, ...newItems]
        localCopyGroup.loading = false
      }
      // this.localCopyOfGroups.splice(index, 1, {
      //   ...localCopyGroup,
      //   items: [...selectedItems, ...newItems]
      // })

      this.$emit('search', text, localCopyGroup)
    }
  }

  showUnsubbedModal() {
    this.subscriptionModalVisible = true
  }

  updateGroups(groups: FacetGroup[]) {
    this.groups = groups
  }
}
