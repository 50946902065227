
import { Component, Prop, Vue } from 'vue-property-decorator'
import FacetsSelector from './FacetsSelector.vue'
import { FacetGroup } from './types'

@Component({
  components: {
    FacetsSelector,
  },
})
export default class FacetsBody extends Vue {
  @Prop({ required: true }) groups!: FacetGroup[]
  @Prop({ default: false }) loading!: boolean
  @Prop({ default: false }) subqueryInProgress!: boolean
  @Prop({ default: false }) unsubbed!: boolean

  //
  // COMPUTED PROPERTIES
  //
  get noFiltersAvailable() {
    return !this.hasFacets && !this.subqueryInProgress
  }

  get hasFacets() {
    return this.groups.reduce((acc, group) => acc || group.items.length > 0, false)
  }

  //
  // METHODS
  //
  onClearGroupHandler(updatedGroup: FacetGroup) {
    this.$emit('clearGroup', updatedGroup)
  }

  onApplyGroupHandler(groupId: string) {
    this.$emit('applyGroup', groupId)
  }

  onChangeGroupHandler(updatedGroup: FacetGroup) {
    this.$emit('changeGroup', updatedGroup)
  }

  onSearchHandler(text: string, groupId: string) {
    this.$emit('search', text, groupId)
  }

  showUnsubbedModal() {
    this.$emit('showUnsubbedModal')
  }

  focusResults() {
    const rawresults = [...document.getElementsByClassName('search-results')]
    if (rawresults.length === 1) {
      // With only one search-result on the page, it can just be focused
      (rawresults[0] as HTMLElement).focus()
    } else {
      // Focus the one that's visible (the Author profile has 4)
      const results = rawresults.filter(x => {
        const closest = x.closest('.tab-pane')
        return closest && (closest as HTMLElement).style.display === ''
      })
      if (results.length === 1) {
        (results[0] as HTMLElement).focus()
      }
    }
  }
}
