
import { Component, Prop, VModel, Vue } from 'vue-property-decorator'
import { CheckboxStage } from './types'

@Component
export default class ThreeStageCheckbox extends Vue {
  @VModel() stage!: CheckboxStage

  @Prop({ default: '' }) readonly label!: string
  @Prop() readonly count!: number
  @Prop({ default: false }) unsubbed!: boolean // if true, display the unsubscribed view of MathSciNet

  get imageInfo() {
    const stages = {
      [CheckboxStage.Unselected]: { icon: 'square', class: 'unselected', dataTestId: 'unselected-chk' },
      [CheckboxStage.Include]: { icon: 'check-square', class: 'included', dataTestId: 'included-chk' },
      [CheckboxStage.Exclude]: { icon: 'minus-square', class: 'excluded', dataTestId: 'excluded-chk' },
    }

    return stages[this.stage || CheckboxStage.Unselected]
  }

  get title() {
    return this.stage === CheckboxStage.Unselected ? '' : `${this.stage} ${this.label}`.trim()
  }

  get ariaChecked() {
    // aria-checked only allows true/false/mixed
    if (this.stage === CheckboxStage.Unselected) {
      return 'false'
    } else if (this.stage === CheckboxStage.Include) {
      return 'true'
    }
    return 'mixed'
  }

  toggleStatus() {
    if (this.stage === CheckboxStage.Unselected) {
      this.stage = CheckboxStage.Include
    } else if (this.stage === CheckboxStage.Include) {
      this.stage = CheckboxStage.Exclude
    } else if (this.stage === CheckboxStage.Exclude) {
      this.stage = CheckboxStage.Unselected
    }
  }

  showUnsubbedModal() {
    this.$emit('showUnsubbedModal')
  }
}

